<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%">
      <div class="title">电动车模板管理</div>
      <div class="formDiv1" style="margin-left: 4vw; margin-top: 20px">
        <el-form label-position="right" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="6"></el-col>
            <el-col :span="8">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="FormData1.price_name"
                  placeholder="请输入模板名称"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
                <el-form-item label="车型:">
                  <el-select v-model="FormData.type_id" placeholder="请选择充电类型">
                    <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label"/>
                  </el-select>
                </el-form-item>
              </el-col> -->

            <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->

      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData1"
          border
          v-loading="loading"
          height="530px"
          style="width: 90%; margin: 30px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="模板id" prop="id" />
          <el-table-column label="模板名称" prop="price_name" />
          <!-- <el-table-column label="单价(元)" prop="price" /> -->
          <el-table-column label="类型" prop="type">
            <template #default="scope">
              <span v-if="scope.row.type === 0">小时</span>
              <span v-if="scope.row.type === 1">度</span>
              <span v-if="scope.row.type === 2">功率</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center">
            <template #default="scope">
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="handleEdit(scope.$index, scope.row)"
                >
                  编辑
                </div> -->
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                round
                type="warning"
                plain
                size="mini"
                @click="del(scope.$index, scope.row)"
                >删除</el-button
              >
              <!-- <div
                  class="BtnSearch1 BtnSearch"
                  @click="del(scope.$index, scope.row)"
                >
                  删除
                </div> -->
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="FormData1.page"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>

  <!-- </div> -->

  <div v-dialogdrag>
    <el-dialog
      v-model="Dialog.editLevy"
      :title="Dialog.title"
      width="820px"
      :before-close="closeDialog"
      class="dialog"
    >
      <div class="formDiv">
        <el-form
          ref="FormRules"
          :rules="rules"
          :model="DialogData"
          label-position="right"
          label-width="100px"
        >
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="模板ID:">
                <el-input v-model="Dialog.DialogData.id" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="模板名称:">
                <el-input
                  v-model="Dialog.DialogData.price_name"
                  placeholder="请输入模板名称"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="9">
              <el-form-item label="计费类型:">
                <el-select
                  @change="typeEdit"
                  style="width: 100%"
                  v-model="Dialog.DialogData.type"
                  :disabled="isEdit"
                >
                  <el-option value="0" label="小时" />
                  <el-option value="1" label="度" />
                  <el-option value="2" label="功率" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" v-show="type1_show">
              <el-form-item label="单价:">
                <el-input
                  v-model="Dialog.DialogData.price"
                  placeholder="请输入单价"
                  :disabled="isEdit"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <div style="margin-top: 10px" v-show="type2_show">
            <el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">0</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame0"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame03"></el-input>
              </el-col>
              元/小时
            </el-row>
            <el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame0 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame1"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame013"></el-input>
              </el-col>
              元/小时 </el-row
            ><el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame1 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame2"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame023"></el-input>
              </el-col>
              元/小时 </el-row
            ><el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame2 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame3"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame33"></el-input>
              </el-col>
              元/小时 </el-row
            ><el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame3 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame4"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame43"></el-input>
              </el-col>
              元/小时 </el-row
            ><el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame4 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame5"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame53"></el-input>
              </el-col>
              元/小时 </el-row
            ><el-row :gutter="20" class="inputs1">
              <el-col :span="3"></el-col>
              <el-col :span="3">
                <p class="up1">{{ Dialog.DialogData.time_frame5 }}</p>
              </el-col>
              -
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame6"></el-input>
              </el-col>
              W
              <el-col :span="0.5"></el-col>
              <el-col :span="3">
                <el-input v-model="Dialog.DialogData.time_frame63"></el-input>
              </el-col>
              元/小时
            </el-row>
          </div>

          <!-- 四轮 -->
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer" v-show="!isEdit">
          <el-button class="Btn" @click="closeDialog">取消</el-button>
          <el-button class="Btn" type="primary" @click="submitDialog"
            >提交</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
  <!-- </div> -->
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_template, upd_template, delete_template } from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  name: "EditArea",
  setup() {
    const data = reactive({
      //表单数据
      carList: [
        {
          value: 2,
          label: "电动车",
        },
        {
          value: 4,
          label: "电动汽车",
        },
      ],
      car: "",
      typeList: [
        {
          value: 0,
          label: "小时",
        },
        {
          value: 1,
          label: "度",
        },
      ],
      type: "",
      FormData1: {
        username: localStorage.getItem("username"),
        type_id: "2",
        page: 1,
      },
      tableData1: [],
      tableData2: [],
      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
        title: "",
      },
      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      total1: 400,
      checked: 0,
      small: true,
      isEdit: false,
      type1_show: true,
      type2_show: false,
    });
    const searchBtn = () => {
      data.FormData1.page = 1;
      getList();
    };

    const getList = () => {
      const dataa = data.FormData1;
      // const dataa = {
      //   price_name: data.FormData1.price_name,
      //   username: localStorage.getItem("username"),
      //   type_id: "2",
      // };
      data.loading = true;
      sel_template(dataa).then((res) => {
        console.log(res, "模板列表");
        if (res) {
          console.log(res, "模板列表");
          data.loading = false;
          if (res.code == 200) {
            data.tableData1 = res.data;
            // data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.FormData1.page = val;
      getList();
    };
    const handleEdit = (index, row) => {
      console.log(row, "点击编辑！！");
      if (row.type == 0 || row.type == 1) {
        (data.type1_show = true), (data.type2_show = false);
      } else if (row.type == 2) {
        (data.type1_show = false), (data.type2_show = true);
      }
      data.Dialog.editLevy = true;
      data.Dialog.title = "编辑";
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
      data.isEdit = false;
    };
    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog.DialogData = {};
    };
    const detail = (index, row) => {
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = row;
      data.isEdit = true;
      data.Dialog.title = "详情";
    };
    const submitDialog = () => {
      const dataa = data.Dialog.DialogData;
      upd_template(dataa).then((res) => {
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };

    const del = (index, row) => {
      ElMessageBox.confirm("您确认删除此模板吗？", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const dataa = {
          id: row.id,
        };
        delete_template(dataa)
          .then((res) => {
            if (res.code == 200) {
              ElMessage.success(res.msg);
              getList();
            } else {
              ElMessage.error("删除失败");
            }
          })
          .catch(() => {
            ElMessage.error("操作失败");
          });
      });
    };

    const typeEdit = (value) => {
      console.log(value, "aaaaaaaa");
      if (value == 2) {
        data.type1_show = false;
        data.type2_show = true;
      } else {
        data.type1_show = true;
        data.type2_show = false;
      }
    };
    onMounted(() => {
      getList();
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleSizeChange,
      handleCurrentChange,
      handleEdit,
      del,
      detail,
      closeDialog,
      submitDialog,
      typeEdit,
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 20px;
}
.el-row {
  height: 45px;
}
.el-select /deep/ .el-input__wrapper {
  width: 288px !important;
  --el-input-focus-border-color: #7bd8d3;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  margin-left: -100px;
}
.Btns {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 175px;
  margin-left: 40px;
  margin-top: 20px;
}
.btn1 {
  margin-left: 340px;
}
.btn:hover {
  background-color: #05d5c7;
  border-color: #05d5c7;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch {
  float: left;
  width: 70px;
  margin: 0 4px;
  margin-top: 8px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.flex {
  display: flex;
}
.s1 {
  width: 35% !important;
}
.s2 {
  width: 65% !important;
}
/* .title{
  margin-left: 5%;
} */
.inputs1 {
  margin-bottom: 4px;
  line-height: 40px;
}
.up1 {
  width: 80px;
  border-radius: 5px;
  height: 40px;
  border: 1px solid lightseagreen;
  background-color: rgb(247, 255, 255);
  line-height: 40px;
  text-align: center;
}
</style>
